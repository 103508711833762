.login-page-wrapper {
    display: flex;
    width: 100vw!important;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0!important;
}
.login-title {
    width: 100%;
    text-align: center;
}
.any-center > img {
    width: 80px;
    height: 80px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}
.full-width {
    width: 100%;
}
.min-with-300 {
    min-width: 300px;
}