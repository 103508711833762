:root {
  font-size: 16px;
}
html, body, #root {
  display: flex;
  width: 100%!important;
  max-width: 100%;
  height: -webkit-fit-content!important;
  height: -moz-fit-content!important;
  height: fit-content!important;
  margin: 0px;
}
.pnlab-admin-wrapper {
  display: flex;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
}
.pnlab-admin-wrapper .page {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.pnlab-admin-wrapper .page-main {
  display: flex;
  flex-flow: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.main-layout {
  display: flex;
  justify-content: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
}
.pnlab-admin-wrapper .main-page {
  display: flex;
  width: 100%;
  justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.pnlab-admin-wrapper .page-content {
  display: flex;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.space-between {
  justify-content: space-between;
  width: 100%;
  display: flex;
}
/* Loading screen */
.hidden {
  display: none;
}
.overlay-loading {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(123,123,123,0.6);
  align-items: center;
  justify-content: center;
  z-index: 9990;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
.right {
  float: right;
}
.left {
  float: left;
}
.flex-center {
  justify-content: center;
}
.missing-margin {
  margin-top: 16px!important;
  margin-bottom: 8px!important;
}

/* Page CSS */
.mc_page {
  display: flex;
  width: 100%;
  /* height: calc(100vh - 124px); */
  justify-content: center;
  margin: 30px 0px;
  padding: 0 15px;
}
.mc_page_title {
  display: flex;
  width: 100%;
  height: 40px;
  /* padding: 0 14px; */
}
.mc_page > .container {
  padding: 30px 15px;
}
.mc_page * {
  max-width: 100%;
  font-weight: 400;
}

.padding-badge {
	color: white;
    background-color: red;
    padding: 0px 2px;
    border-radius: 20px;
    margin: 0 5px;
}
/* Button CSS */
.small-btn svg {
  height: 20px;
  width: 20px;
}
.small-btn {
  text-transform: uppercase;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login-page-wrapper {
    display: flex;
    width: 100vw!important;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0!important;
}
.login-title {
    width: 100%;
    text-align: center;
}
.any-center > img {
    width: 80px;
    height: 80px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}
.full-width {
    width: 100%;
}
.min-with-300 {
    min-width: 300px;
}


.table-wrapper {
    position: relative;
    /* overflow-x: scroll; */
}
.table-wrapper > .search-text {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    max-height: 40px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 99;
}
.search-text .totals {
    margin: 0;
}
.search-text .totals span {
    margin: 0!important;
    display: inline!important;
    font-size: 18px;
}
.vertical-align-middle {
    vertical-align: middle!important;
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-add-btn {
    position: absolute!important;
    right: 0;
    bottom: 50px;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
}
.table {
    position: relative;
}
.table-scrollable {
    width: 100%;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
    padding-top: 65px;
}
.footer-padding {
    padding-top: 20px;
    padding-bottom: 10px;
}
.footer-padding {
    padding-top: 20px;
    padding-bottom: 10px;
}
.uploader-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.image-preview-wrapper {
    display: flex;
    max-height: 200px;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid rgba(0, 40, 100, 0.12);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 150px;
}
.image-preview-wrapper img.image-responsive {
    height: calc(100% - 10px);
    width: auto;
    margin: 5px;
}
.image-preview-wrapper video.image-responsive {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    margin: 5px;
}
.image-preview-wrapper .image-uploading-overlay {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(123,123,123,0.8); 
    z-index: 10;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}
.image-preview-wrapper .hidden {
    display: none;
}
.section-header {
	margin-top: 15px;
	margin-bottom: 15px;
}
.vertical-align-middle {
	vertical-align: middle !important;
	white-space: nowrap;
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.add-icon {
	position: relative;
	left: 100%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	bottom: 100%;
}
.smaller-width {
	max-width: 200px;
}
.attachment-width {
	max-width: calc(100% - 5 * 200px);
}
.fullwidth {
	width: 100%;
}
.table-scroll {
	overflow: hidden;
	overflow-x: scroll;
}
.align-center {
	align-items: center;
}
.actions {
	position: absolute;
	left: 0;
	-webkit-transform: translate(-100%, -23px);
	        transform: translate(-100%, -23px);
	background-color: rgba(255, 255, 255, 0.8);
	-webkit-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
.uploader-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.react-select-wrapper {
	width: 100%;
	position: relative;
}
.react-select {
	width: 100%;
}
.react-input {
	display: inline-flex;
	background-color: #f3f3f3;
	top: 1rem;
	height: 1.75rem;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
}
.select-list {
	position: absolute;
    width: 100%;
	z-index: 99;
	max-height: 30vh;
    overflow: hidden;
    overflow-y: scroll;
}
.position-relative {
	position: relative;
}
.finance-view-payment {
	max-width: 120px;
	max-height: 70px;
}
.bottom-border {
	border-bottom: 1px solid #555563;
}
.d-flex {
	display: flex;
}

.footer-padding {
    padding-top: 20px;
    padding-bottom: 10px;
}
.footer-padding {
    padding-top: 20px;
    padding-bottom: 10px;
}
.footer-padding {
    padding-top: 20px;
    padding-bottom: 10px;
}
.sidebar-component {
}
.navbar-component {
    width: 300px;
}

.dashboard-layout-wrapper {
    width: 100%;
    height: 100%;
}
.topbar-left {
    display: flex;
    align-items: center;
}
.topbar-right {
    display: flex;
    align-items: center;
}
.align-left-right {
    display: flex;
    justify-content: space-between;
}
.navbar-component {
    /* background-color: #68a4f3; */
}
