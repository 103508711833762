.react-select-wrapper {
	width: 100%;
	position: relative;
}
.react-select {
	width: 100%;
}
.react-input {
	display: inline-flex;
	background-color: #f3f3f3;
	top: 1rem;
	height: 1.75rem;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
}
.select-list {
	position: absolute;
    width: 100%;
	z-index: 99;
	max-height: 30vh;
    overflow: hidden;
    overflow-y: scroll;
}
.position-relative {
	position: relative;
}