:root {
  font-size: 16px;
}
html, body, #root {
  display: flex;
  width: 100%!important;
  max-width: 100%;
  height: fit-content!important;
  margin: 0px;
}
.pnlab-admin-wrapper {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
}
.pnlab-admin-wrapper .page {
  width: 100%;
  height: fit-content;
}
.pnlab-admin-wrapper .page-main {
  display: flex;
  flex-flow: column;
  height: fit-content;
}
.main-layout {
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  overflow: hidden;
}
.pnlab-admin-wrapper .main-page {
  display: flex;
  width: 100%;
  justify-content: center;
  height: fit-content;
}
.pnlab-admin-wrapper .page-content {
  display: flex;
  width: 100%;
  height: fit-content;
}
.space-between {
  justify-content: space-between;
  width: 100%;
  display: flex;
}
/* Loading screen */
.hidden {
  display: none;
}
.overlay-loading {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(123,123,123,0.6);
  align-items: center;
  justify-content: center;
  z-index: 9990;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.right {
  float: right;
}
.left {
  float: left;
}
.flex-center {
  justify-content: center;
}
.missing-margin {
  margin-top: 16px!important;
  margin-bottom: 8px!important;
}

/* Page CSS */
.mc_page {
  display: flex;
  width: 100%;
  /* height: calc(100vh - 124px); */
  justify-content: center;
  margin: 30px 0px;
  padding: 0 15px;
}
.mc_page_title {
  display: flex;
  width: 100%;
  height: 40px;
  /* padding: 0 14px; */
}
.mc_page > .container {
  padding: 30px 15px;
}
.mc_page * {
  max-width: 100%;
  font-weight: 400;
}

.padding-badge {
	color: white;
    background-color: red;
    padding: 0px 2px;
    border-radius: 20px;
    margin: 0 5px;
}
/* Button CSS */
.small-btn svg {
  height: 20px;
  width: 20px;
}
.small-btn {
  text-transform: uppercase;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
