.finance-view-payment {
	max-width: 120px;
	max-height: 70px;
}
.bottom-border {
	border-bottom: 1px solid #555563;
}
.d-flex {
	display: flex;
}
