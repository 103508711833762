.table-wrapper {
    position: relative;
    /* overflow-x: scroll; */
}
.table-wrapper > .search-text {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    max-height: 40px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 99;
}
.search-text .totals {
    margin: 0;
}
.search-text .totals span {
    margin: 0!important;
    display: inline!important;
    font-size: 18px;
}
.vertical-align-middle {
    vertical-align: middle!important;
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-add-btn {
    position: absolute!important;
    right: 0;
    bottom: 50px;
    transform: translate(50%, -50%);
}
.table {
    position: relative;
}
.table-scrollable {
    width: 100%;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
    padding-top: 65px;
}