.section-header {
	margin-top: 15px;
	margin-bottom: 15px;
}
.vertical-align-middle {
	vertical-align: middle !important;
	white-space: nowrap;
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.add-icon {
	position: relative;
	left: 100%;
	transform: translate(-50%, -50%);
	bottom: 100%;
}
.smaller-width {
	max-width: 200px;
}
.attachment-width {
	max-width: calc(100% - 5 * 200px);
}
.fullwidth {
	width: 100%;
}
.table-scroll {
	overflow: hidden;
	overflow-x: scroll;
}
.align-center {
	align-items: center;
}
.actions {
	position: absolute;
	left: 0;
	transform: translate(-100%, -23px);
	background-color: rgba(255, 255, 255, 0.8);
	transition: all 0.1s linear;
}