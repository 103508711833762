.dashboard-layout-wrapper {
    width: 100%;
    height: 100%;
}
.topbar-left {
    display: flex;
    align-items: center;
}
.topbar-right {
    display: flex;
    align-items: center;
}
.align-left-right {
    display: flex;
    justify-content: space-between;
}