.image-preview-wrapper {
    display: flex;
    max-height: 200px;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid rgba(0, 40, 100, 0.12);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 150px;
}
.image-preview-wrapper img.image-responsive {
    height: calc(100% - 10px);
    width: auto;
    margin: 5px;
}
.image-preview-wrapper video.image-responsive {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    margin: 5px;
}
.image-preview-wrapper .image-uploading-overlay {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(123,123,123,0.8); 
    z-index: 10;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}
.image-preview-wrapper .hidden {
    display: none;
}